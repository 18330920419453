import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faEdit,
  faTrash,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { PermissionWrapper } from '../../components/Permission';

// Tipos
interface Conta {
  id: number;
  nome: string;
  email: string;
  ativo: boolean;
  imposto: number;
  created_at: string;
  updated_at: string;
}

interface ApiResponse {
  data: Conta[];
  meta: {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
    to: number;
  };
}

interface NewConta {
  nome: string;
  email: string;
  ativo: boolean;
  imposto: number;
}

// Animações
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideIn = keyframes`
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

// Componentes estilizados
const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  animation: ${fadeIn} 0.5s ease-out;
`;

const Title = styled.h1`
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 700;
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const SearchInput = styled.input`
  width: 100%;
  max-width: 300px;
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.5);
  }
`;

const ContaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
`;

const ContaCard = styled.div<{ ativo: boolean }>`
  background-color: ${(props) => (props.ativo ? '#ffffff' : '#f8f9fa')};
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: all 0.3s ease;
  animation: ${slideIn} 0.5s ease-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const ContaNome = styled.h2`
  font-size: 1.2rem;
  color: #34495e;
  margin-bottom: 1rem;
`;

const ContaInfo = styled.p`
  font-size: 0.9rem;
  color: #7f8c8d;
  margin-bottom: 0.5rem;
`;

const ContaStatus = styled.div<{ ativo: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => (props.ativo ? '#27ae60' : '#e74c3c')};
  font-size: 0.9rem;
  margin-top: 1rem;
`;

const ContaActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const ActionIcon = styled(FontAwesomeIcon)`
  margin-left: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.2);
  }
`;

const Pagination = styled.nav`
  margin-top: 2rem;

  ul {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  li {
    margin: 0 0.25rem;
  }

  button {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    color: #007bff;
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    transition: all 0.3s ease;

    &:hover:not(:disabled) {
      background-color: #007bff;
      color: white;
    }

    &:disabled {
      color: #6c757d;
      cursor: not-allowed;
    }
  }

  .active button {
    background-color: #007bff;
    color: white;
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    background: linear-gradient(135deg, #3498db, #2980b9);
    color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .modal-footer {
    border-top: none;
  }
`;

const FormGroup = styled(Form.Group)`
  margin-bottom: 1.5rem;
`;

// Componente principal
const Contas: React.FC = () => {
  const [contas, setContas] = useState<Conta[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(5);
  const [total, setTotal] = useState<number>(0);
  const [to, setTo] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedConta, setSelectedConta] = useState<Conta | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newConta, setNewConta] = useState<NewConta>({
    nome: '',
    email: '',
    ativo: true,
    imposto: 0,
  });
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ nome?: string; email?: string }>({});

  const fetchContas = async (page: number, searchQuery: string = '') => {
    setLoading(true);
    setError(null);

    const token = sessionStorage.getItem('authToken');
    if (!token) {
      setError('Token não encontrado. Por favor, faça login novamente.');
      setLoading(false);
      return;
    }

    try {
      let url = `${
        import.meta.env.VITE_APP_API_URL
      }/meli-contas?page=${page}&per_page=${perPage}`;
      if (searchQuery) {
        url += `&search=${searchQuery}`;
      }

      const response = await axios.get<ApiResponse>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setContas(response.data.data);
      setCurrentPage(response.data.meta.current_page);
      setTotalPages(response.data.meta.last_page);
      setPerPage(response.data.meta.per_page);
      setTotal(response.data.meta.total);
      setTo(response.data.meta.to);
    } catch (error) {
      console.error('Erro ao buscar contas:', error);
      setError('Erro ao buscar contas.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContas(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      fetchContas(1, search);
    }
  };

  const handleShowCreateModal = () => {
    setShowCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    setNewConta({ nome: '', email: '', ativo: true, imposto: 0 });
    setErrors({});
    setIsEditing(false);
  };

  const handleShowDeleteModal = (conta: Conta) => {
    setSelectedConta(conta);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedConta(null);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setNewConta((prev) => ({
      ...prev,
      [name]:
        type === 'checkbox'
          ? checked
          : name === 'imposto'
          ? parseFloat(value) || 0
          : value,
    }));
  };

  const validateForm = () => {
    const { nome, email } = newConta;
    const newErrors: { nome?: string; email?: string } = {};
    if (!nome) {
      newErrors.nome = 'Nome é obrigatório';
    }
    if (!email) {
      newErrors.email = 'Email é obrigatório';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email inválido';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreateConta = async () => {
    if (!validateForm()) {
      return;
    }

    setIsSaving(true);
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      setError('Token não encontrado. Por favor, faça login novamente.');
      setIsSaving(false);
      return;
    }

    try {
      await axios.post(
        `${import.meta.env.VITE_APP_API_URL}/meli-contas`,
        newConta,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      handleCloseCreateModal();
      fetchContas(currentPage);
    } catch (error) {
      console.error('Erro ao criar conta:', error);
      setError('Erro ao criar conta.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleEditConta = (conta: Conta) => {
    setSelectedConta(conta);
    setNewConta({
      nome: conta.nome,
      email: conta.email,
      ativo: conta.ativo,
      imposto: conta.imposto,
    });
    setIsEditing(true);
    setShowCreateModal(true);
  };

  const handleUpdateConta = async () => {
    if (!validateForm()) {
      return;
    }

    setIsSaving(true);
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      setError('Token não encontrado. Por favor, faça login novamente.');
      setIsSaving(false);
      return;
    }

    try {
      await axios.put(
        `${import.meta.env.VITE_APP_API_URL}/meli-contas/${selectedConta?.id}`,
        newConta,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      handleCloseCreateModal();
      fetchContas(currentPage);
    } catch (error) {
      console.error('Erro ao atualizar conta:', error);
      setError('Erro ao atualizar conta.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteConta = async () => {
    setIsSaving(true);
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      setError('Token não encontrado. Por favor, faça login novamente.');
      setIsSaving(false);
      return;
    }

    try {
      await axios.delete(
        `${import.meta.env.VITE_APP_API_URL}/meli-contas/${selectedConta?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      handleCloseDeleteModal();
      fetchContas(currentPage);
    } catch (error) {
      console.error('Erro ao excluir conta:', error);
      setError('Erro ao excluir conta.');
    } finally {
      setIsSaving(false);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    let endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow);

    if (currentPage <= halfMaxPagesToShow) {
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (currentPage + halfMaxPagesToShow >= totalPages) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <PermissionWrapper screenId={'9'} permission="access">
      <PageContainer>
        <Title>Gerenciamento de Contas</Title>
        <ActionBar>
          <Button onClick={handleShowCreateModal}>
            <FontAwesomeIcon icon={faPlus} /> Nova Conta
          </Button>
          <SearchInput
            placeholder="Pesquisar contas..."
            value={search}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyPress}
          />
        </ActionBar>

        {loading ? (
          <Spinner animation="border" role="status" />
        ) : error ? (
          <Alert variant="danger">{error}</Alert>
        ) : (
          <>
            <ContaGrid>
              {contas.map((conta) => (
                <ContaCard key={conta.id} ativo={conta.ativo}>
                  <ContaNome>{conta.nome}</ContaNome>
                  <ContaInfo>
                    <strong>Email:</strong> {conta.email}
                  </ContaInfo>
                  <ContaInfo>
                    <strong>Imposto:</strong> {conta.imposto}%
                  </ContaInfo>
                  <ContaInfo>
                    <strong>Criado em:</strong>{' '}
                    {new Date(conta.created_at).toLocaleDateString()}
                  </ContaInfo>
                  <ContaStatus ativo={conta.ativo}>
                    <FontAwesomeIcon
                      icon={conta.ativo ? faCheckCircle : faTimesCircle}
                    />
                    <span style={{ marginLeft: '0.5rem' }}>
                      {conta.ativo ? 'Ativo' : 'Inativo'}
                    </span>
                  </ContaStatus>
                  <ContaActions>
                    <ActionIcon
                      icon={faEdit}
                      onClick={() => handleEditConta(conta)}
                    />
                    <ActionIcon
                      icon={faTrash}
                      onClick={() => handleShowDeleteModal(conta)}
                    />
                  </ContaActions>
                </ContaCard>
              ))}
            </ContaGrid>
            <p className="text-muted text-center mt-4">
              Mostrando {to} de {total} contas
            </p>
            <Pagination>
              <ul>
                <li className={currentPage === 1 ? 'disabled' : ''}>
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Anterior
                  </button>
                </li>
                {getPageNumbers().map((pageNumber) => (
                  <li
                    key={pageNumber}
                    className={currentPage === pageNumber ? 'active' : ''}
                  >
                    <button onClick={() => handlePageChange(pageNumber)}>
                      {pageNumber}
                    </button>
                  </li>
                ))}
                <li className={currentPage === totalPages ? 'disabled' : ''}>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Próxima
                  </button>
                </li>
              </ul>
            </Pagination>
          </>
        )}

        <StyledModal show={showCreateModal} onHide={handleCloseCreateModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {isEditing ? 'Editar Conta' : 'Cadastrar Nova Conta'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup controlId="nome">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  name="nome"
                  placeholder="Nome da conta"
                  value={newConta.nome}
                  onChange={handleInputChange}
                  isInvalid={!!errors.nome}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email da conta"
                  value={newConta.email}
                  onChange={handleInputChange}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </FormGroup>
              <FormGroup controlId="imposto">
                <Form.Label>Imposto (%)</Form.Label>
                <Form.Control
                  type="number"
                  name="imposto"
                  placeholder="Imposto da conta"
                  step="0.01"
                  value={newConta.imposto}
                  onChange={handleInputChange}
                />
                <Form.Text className="text-muted">
                  O valor do imposto é calculado com base na porcentagem de
                  imposto e no valor da venda.
                </Form.Text>
              </FormGroup>
              <FormGroup controlId="ativo">
                <Form.Check
                  type="switch"
                  label="Conta Ativa"
                  name="ativo"
                  checked={newConta.ativo}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseCreateModal}>
              Cancelar
            </Button>
            <Button
              onClick={isEditing ? handleUpdateConta : handleCreateConta}
              disabled={isSaving}
            >
              {isSaving ? (
                <Spinner
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : isEditing ? (
                'Atualizar'
              ) : (
                'Cadastrar'
              )}
            </Button>
          </Modal.Footer>
        </StyledModal>

        <StyledModal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar Exclusão</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Tem certeza de que deseja excluir a conta {selectedConta?.nome}?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              Cancelar
            </Button>
            <Button
              variant="danger"
              onClick={handleDeleteConta}
              disabled={isSaving}
            >
              {isSaving ? (
                <Spinner
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Excluir'
              )}
            </Button>
          </Modal.Footer>
        </StyledModal>
      </PageContainer>
    </PermissionWrapper>
  );
};

export default Contas;
