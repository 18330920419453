import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Modal, Button, Form } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faEnvelope,
  faLock,
  faCheck,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import { refreshToken } from '../../components/Token/refreshToken';

// Types and Interfaces
interface User {
  id: number;
  name: string;
  email: string;
  email_verified_at: string | null;
  two_factor_confirmed_at: string | null;
  current_team_id: number | null;
  profile_photo_path: string | null;
  created_at: string;
  updated_at: string;
}

interface UserWithPassword extends User {
  password?: string;
  confirmPassword?: string;
}

interface MeliAccount {
  id: string;
  nome: string;
  ativo: boolean;
}

interface ApiResponseData<T> {
  data: T;
  message: string;
}

// Styled Components
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const PageContainer = styled.div`
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s ease-out;
`;

const Title = styled.h1`
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 700;
`;

const StyledForm = styled.form`
  display: grid;
  gap: 1.5rem;
`;

const InputGroup = styled.div`
  position: relative;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;

  &:focus {
    border-color: #3498db;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
    outline: none;
  }
`;

const InputIcon = styled(FontAwesomeIcon)`
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #7f8c8d;
`;

const SubmitButton = styled.button`
  background: linear-gradient(to right, #3498db, #2980b9);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: linear-gradient(to right, #2980b9, #3498db);
  }

  &:disabled {
    background: #95a5a6;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.p`
  color: #e74c3c;
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;

const StyledModal = styled(Modal)`
  .modal-content {
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    border-radius: 15px;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    border-top: none;
  }
`;

// Main Component
const Usuario: React.FC = () => {
  const [user, setUser] = useState<UserWithPassword>({
    id: 0,
    name: '',
    email: '',
    email_verified_at: null,
    two_factor_confirmed_at: null,
    current_team_id: null,
    profile_photo_path: null,
    created_at: '',
    updated_at: '',
    password: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [meliAccounts, setMeliAccounts] = useState<MeliAccount[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [meliUserInfo, setMeliUserInfo] = useState<any>(null);
  const authToken = sessionStorage.getItem('authToken');
  const userId = sessionStorage.getItem('userId');

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get<User>(
          `${import.meta.env.VITE_APP_API_URL}/users/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setUser(response.data);
      } catch (error) {
        console.error('Erro ao buscar usuário:', error);
        toast.error('Erro ao buscar dados do usuário.');
      }
    };

    fetchUser();
    fetchMeliAccounts();
  }, [authToken, userId]);

  const fetchMeliAccounts = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/meli-contas`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setMeliAccounts(response.data.data);
    } catch (error) {
      console.error('Erro ao buscar contas do Mercado Livre:', error);
      toast.error('Erro ao buscar contas do Mercado Livre');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPasswordError(null);
    if (user.password !== user.confirmPassword) {
      setPasswordError('As senhas não coincidem.');
      return;
    }
    setLoading(true);
    try {
      const { confirmPassword, ...userData } = user;
      await axios.put<ApiResponseData<User>>(
        `${import.meta.env.VITE_APP_API_URL}/users/${user.id}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success('Informações atualizadas com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar usuário:', error);
      toast.error('Erro ao atualizar informações.');
    } finally {
      setLoading(false);
    }
  };

  const handleIntegrationCheck = async () => {
    if (selectedAccount) {
      setLoading(true);
      try {
        await refreshToken(selectedAccount, setLoading);
        const token = sessionStorage.getItem('ml_access_token');
        const response = await axios.get(
          `https://api.mercadolibre.com/users/me`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMeliUserInfo(response.data);
        setShowUserModal(true);
      } catch (error) {
        console.error('Erro ao verificar integração:', error);
        toast.error('Erro ao verificar integração.');
      } finally {
        setLoading(false);
        setShowModal(false);
      }
    } else {
      toast.error('Selecione uma conta do Mercado Livre.');
    }
  };

  return (
    <PageContainer>
      <Title>Perfil do Usuário</Title>
      <StyledForm onSubmit={handleSubmit}>
        <InputGroup>
          <InputIcon icon={faUser} />
          <StyledInput
            type="text"
            id="name"
            name="name"
            value={user.name}
            onChange={handleChange}
            placeholder="Nome"
          />
        </InputGroup>
        <InputGroup>
          <InputIcon icon={faEnvelope} />
          <StyledInput
            type="email"
            id="email"
            name="email"
            value={user.email}
            onChange={handleChange}
            placeholder="Email"
          />
        </InputGroup>
        <InputGroup>
          <InputIcon icon={faLock} />
          <StyledInput
            type="password"
            id="password"
            name="password"
            value={user.password || ''}
            onChange={handleChange}
            placeholder="Nova Senha"
          />
        </InputGroup>
        <InputGroup>
          <InputIcon icon={faCheck} />
          <StyledInput
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={user.confirmPassword || ''}
            onChange={handleChange}
            placeholder="Confirme a Nova Senha"
          />
        </InputGroup>
        {passwordError && <ErrorMessage>{passwordError}</ErrorMessage>}
        <SubmitButton type="submit" disabled={loading}>
          {loading ? (
            <FontAwesomeIcon icon={faSyncAlt} spin />
          ) : (
            'Atualizar Perfil'
          )}
        </SubmitButton>
      </StyledForm>
      <br />
      <Button onClick={() => setShowModal(true)}>
        Verificar Integração com Mercado Livre
      </Button>
      <StyledModal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Selecione a Conta do Mercado Livre</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Conta do Mercado Livre</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => setSelectedAccount(e.target.value)}
            >
              <option value="">Selecione uma conta</option>
              {meliAccounts.map((account) => (
                <option key={account.id} value={account.id}>
                  {account.nome} {account.ativo ? '(Ativo)' : ''}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={handleIntegrationCheck}
            disabled={loading}
          >
            {loading ? <FontAwesomeIcon icon={faSyncAlt} spin /> : 'Verificar'}
          </Button>
        </Modal.Footer>
      </StyledModal>
      <StyledModal show={showUserModal} onHide={() => setShowUserModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Informações do Usuário do Mercado Livre</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {meliUserInfo ? (
            <div>
              <p>
                <strong>ID:</strong> {meliUserInfo.id}
              </p>
              <p>
                <strong>Nickname:</strong> {meliUserInfo.nickname}
              </p>
              <p>
                <strong>Email:</strong> {meliUserInfo.email}
              </p>
              <p>
                <strong>Primeiro Nome:</strong> {meliUserInfo.first_name}
              </p>
              <p>
                <strong>Último Nome:</strong> {meliUserInfo.last_name}
              </p>
            </div>
          ) : (
            <p>Não foi possível obter as informações do usuário.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUserModal(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </StyledModal>
    </PageContainer>
  );
};

export default Usuario;
