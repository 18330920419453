import React, { useEffect, useState } from 'react';
import api from '../../services/api'; // Importe a instância de Axios configurada
import { useAuth } from '../../components/Private/AuthContext';
import { toast } from 'react-toastify';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap';
import { LoginResponse } from '../../components/types';
import './login.css';

const Login: React.FC = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { login } = useAuth();
  const [showTrialModal, setShowTrialModal] = useState(false);
  const [trialCredentials, setTrialCredentials] = useState({
    email: '',
    password: '',
  });
  const [trialLoading, setTrialLoading] = useState(false);

  useEffect(() => {
    document.body.classList.add('login-page');
    return () => {
      document.body.classList.remove('login-page');
    };
  }, []);

  useEffect(() => {
    const contentElement = document.querySelector('.content');
    if (contentElement) {
      contentElement.classList.remove('content');
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('trial') !== null) {
      setShowTrialModal(true);
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const handleTrialChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTrialCredentials((prev) => ({ ...prev, [name]: value }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await api.post<LoginResponse>('/login', credentials);
      const { token, user } = response.data;

      sessionStorage.setItem('authToken', token);
      sessionStorage.setItem('userId', user.id.toString());
      sessionStorage.setItem('userEmail', user.email);
      sessionStorage.setItem('userName', user.name);
      sessionStorage.setItem('role', user.role);
      sessionStorage.setItem('roleModulo', user.roleModulo);
      sessionStorage.setItem('database', user.database);
      sessionStorage.setItem('created_at', user.created_at);
      login(token, user);
      if (rememberMe) {
        localStorage.setItem('email', credentials.email);
        localStorage.setItem('password', credentials.password);
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleTrialSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTrialLoading(true);

    try {
      const registerResponse = await api.post(
        '/register-trial',
        trialCredentials,
        {
          headers: {
            'X-TOKEN': 'c028d52e6be24a9f4a717483aa036c8bd44d4e5',
          },
        }
      );

      if (registerResponse.status === 201) {
        const loginResponse = await api.post<LoginResponse>(
          '/login',
          trialCredentials
        );

        const { token, user } = loginResponse.data;

        sessionStorage.setItem('authToken', token);
        sessionStorage.setItem('userId', user.id.toString());
        sessionStorage.setItem('userEmail', user.email);
        sessionStorage.setItem('userName', user.name);
        sessionStorage.setItem('role', user.role);
        sessionStorage.setItem('roleModulo', user.roleModulo);
        sessionStorage.setItem('database', user.database);
        sessionStorage.setItem('created_at', user.created_at);

        login(token, user);

        setShowTrialModal(false);
        setTrialCredentials({ email: '', password: '' });
      } else {
        toast.error(
          'Ocorreu um erro durante o cadastro no plano trial. Tente novamente.'
        );
      }
    } catch (error) {
    } finally {
      setTrialLoading(false);
    }
  };

  const handleForgotPasswordSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setForgotPasswordLoading(true);

    try {
      await api.post('/forgot-password', {
        email: forgotPasswordEmail,
      });
      toast.success(
        'Um link para redefinição de senha foi enviado para seu e-mail.'
      );
      setShowForgotPasswordModal(false);
    } catch (error) {
      toast.error('Ocorreu um erro. Por favor, tente novamente.');
    } finally {
      setForgotPasswordLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2 className="text-center mb-4">Bem-vindo à</h2>
        <img src="/logoheader.png" alt="Integrameli Logo" className="logo" />
        <form onSubmit={handleLogin}>
          <div className="form-group mb-3">
            <label htmlFor="email">E-mail</label>
            <div className="input-group">
              <span className="input-group-text">
                <FaEnvelope />
              </span>
              <input
                type="email"
                className="form-control no-border-radius"
                id="email"
                placeholder="Digite seu e-mail aqui"
                name="email"
                value={credentials.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group mb-3">
            <label htmlFor="password">Senha</label>
            <div className="input-group">
              <span className="input-group-text">
                <FaLock />
              </span>
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control"
                id="password"
                placeholder="Digite sua senha"
                name="password"
                value={credentials.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="btn btn-outline-secondary no-border-radius"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="rememberMe"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <label className="form-check-label" htmlFor="rememberMe">
                Lembrar-me
              </label>
            </div>
            <Button
              variant="link"
              className="text-primary p-0"
              onClick={() => setShowForgotPasswordModal(true)}
            >
              Esqueceu a senha?
            </Button>
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block w-100"
            disabled={loading}
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              'ENTRAR'
            )}
          </button>
        </form>
        <div className="mt-3 text-center">
          <Button
            variant="outline-primary"
            className="btn-block"
            onClick={() => setShowTrialModal(true)}
          >
            Experimentar 7 dias grátis
          </Button>
        </div>
      </div>
      <Modal
        show={showForgotPasswordModal}
        onHide={() => setShowForgotPasswordModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Esqueceu sua senha?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Digite seu e-mail e enviaremos um link para redefinir sua senha.
          </p>
          <form onSubmit={handleForgotPasswordSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="forgotPasswordEmail">E-mail</label>
              <div className="input-group">
                <span className="input-group-text">
                  <FaEnvelope />
                </span>
                <input
                  type="email"
                  className="form-control"
                  id="forgotPasswordEmail"
                  placeholder="Digite seu e-mail"
                  value={forgotPasswordEmail}
                  onChange={(e) => setForgotPasswordEmail(e.target.value)}
                  required
                />
              </div>
            </div>
            <Button
              type="submit"
              className="btn btn-primary btn-block w-100"
              disabled={forgotPasswordLoading}
            >
              {forgotPasswordLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                'Enviar link de redefinição'
              )}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={showTrialModal} onHide={() => setShowTrialModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Cadastre-se para o Plano de Avaliação Gratuita
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleTrialSignup}>
            <div className="form-group mb-3">
              <label htmlFor="trialEmail">E-mail</label>
              <div className="input-group">
                <span className="input-group-text">
                  <FaEnvelope />
                </span>
                <input
                  type="email"
                  className="form-control"
                  id="trialEmail"
                  placeholder="Digite seu e-mail"
                  name="email"
                  value={trialCredentials.email}
                  onChange={handleTrialChange}
                  required
                />
              </div>
            </div>
            <div className="form-group mb-3">
              <label htmlFor="trialPassword">Senha</label>
              <div className="input-group">
                <span className="input-group-text">
                  <FaLock />
                </span>
                <input
                  type="password"
                  className="form-control"
                  id="trialPassword"
                  placeholder="Digite sua senha"
                  name="password"
                  value={trialCredentials.password}
                  onChange={handleTrialChange}
                  required
                />
              </div>
            </div>
            <Button
              type="submit"
              className="btn btn-primary btn-block w-100"
              disabled={trialLoading}
            >
              {trialLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                'Cadastrar Trial'
              )}
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Login;
