import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faEye,
  faPlus,
  faPencilAlt,
  faTrash,
  faInfoCircle,
  faFileExcel,
  faFilePdf,
  faSave,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

type PermissionKey =
  | 'access'
  | 'view'
  | 'create'
  | 'edit'
  | 'delete'
  | 'details'
  | 'exportExcel'
  | 'exportPdf'
  | 'deny';

interface Screen {
  id: number;
  name: string;
}

interface ScreenPermission {
  screenId: number;
  permissions: { [K in PermissionKey]?: boolean };
}

const defaultPermissions: { [K in Exclude<PermissionKey, 'deny'>]: boolean } = {
  access: false,
  view: false,
  create: false,
  edit: false,
  delete: false,
  details: false,
  exportExcel: false,
  exportPdf: false,
};

const permissionLabels: { [K in Exclude<PermissionKey, 'deny'>]: string } = {
  access: 'Acessar Página',
  view: 'Visualizar Registros',
  create: 'Criar',
  edit: 'Editar',
  delete: 'Excluir',
  details: 'Detalhes',
  exportExcel: 'Exportar Excel',
  exportPdf: 'Exportar PDF',
};

const permissionIcons: {
  [K in Exclude<PermissionKey, 'deny'>]: IconDefinition;
} = {
  access: faCheck,
  view: faEye,
  create: faPlus,
  edit: faPencilAlt,
  delete: faTrash,
  details: faInfoCircle,
  exportExcel: faFileExcel,
  exportPdf: faFilePdf,
};

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Header = styled.header`
  text-align: center;
  margin-bottom: 2rem;
`;

const Title = styled.h1`
  color: #343a40;
  font-size: 2rem;
  margin-bottom: 0.5rem;
`;

const Description = styled.p`
  color: #6c757d;
  font-size: 1.1rem;
`;

const ScreenGrid = styled.main`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const ScreenCard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ScreenTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #343a40;
`;

const PermissionList = styled.ul`
  list-style: none;
  padding: 0;
`;

const PermissionItem = styled.li`
  margin-bottom: 0.5rem;
`;

const PermissionLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const PermissionCheckbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 0.5rem;
`;

const PermissionText = styled.span`
  margin-left: 0.5rem;
  color: #343a40;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

const SaveButton = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #218838;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
`;

const PermissionPage: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const [screens, setScreens] = useState<Screen[]>([]);
  const [screenPermissions, setScreenPermissions] = useState<
    ScreenPermission[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const RESTRICTED_SCREENS = [
    'Relatórios',
    'ConsultaPreço',
    'Contas',
    'Integração',
    'Dashboard',
    'Anúncios',
    'Vendas',
    'Estoque',
  ];

  useEffect(() => {
    fetchScreens();
  }, []);

  const fetchScreens = async () => {
    try {
      const token = sessionStorage.getItem('authToken');
      const response = await axios.get<{ screens: Screen[] }>(
        `${import.meta.env.VITE_APP_API_URL}/screens`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setScreens(response.data.screens);
      fetchPermissionsForAllScreens(response.data.screens);
    } catch (error) {
      console.error('Erro ao buscar telas:', error);
      toast.error('Erro ao carregar as telas.');
    }
  };

  const parsePermissions = (
    permissions: string | string[] | null | undefined
  ): { [K in PermissionKey]?: boolean } => {
    if (!permissions) {
      return { ...defaultPermissions };
    }

    let permissionsArray: string[];
    if (Array.isArray(permissions)) {
      permissionsArray = permissions;
    } else if (typeof permissions === 'string') {
      permissionsArray = permissions.split(',').map((p) => p.trim());
    } else {
      return { ...defaultPermissions };
    }

    if (permissionsArray.includes('deny')) {
      return { deny: true };
    }

    return Object.keys(defaultPermissions).reduce(
      (acc, perm) => {
        acc[perm as keyof typeof defaultPermissions] =
          permissionsArray.includes(perm);
        return acc;
      },
      { ...defaultPermissions }
    );
  };

  const fetchPermissionsForAllScreens = async (screens: Screen[]) => {
    try {
      const token = sessionStorage.getItem('authToken');
      const permissionsPromises = screens.map((screen) =>
        axios.get<{
          user_id: number;
          screen_id: number;
          permissions: string | string[] | null;
        }>(
          `${import.meta.env.VITE_APP_API_URL}/screens/${
            screen.id
          }/permissions/${userId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
      );
      const permissionsResponses = await Promise.all(permissionsPromises);
      const screenPermissions = permissionsResponses.map((response) => ({
        screenId: response.data.screen_id,
        permissions: parsePermissions(response.data.permissions),
      }));
      setScreenPermissions(screenPermissions);
    } catch (error) {
      console.error('Erro ao buscar permissões:', error);
      toast.error('Erro ao carregar as permissões.');
    } finally {
      setLoading(false);
    }
  };

  const handlePermissionChange = (
    screenId: number,
    permission: PermissionKey
  ) => {
    setScreenPermissions((prevPermissions) =>
      prevPermissions.map((screenPerm) => {
        if (screenPerm.screenId === screenId) {
          const updatedPermissions = { ...screenPerm.permissions };

          if (permission === 'access' && !updatedPermissions[permission]) {
            delete updatedPermissions['deny'];
          }

          if (
            Object.values(updatedPermissions).every((v) => !v) &&
            permission !== 'access'
          ) {
            updatedPermissions['deny'] = true;
          } else {
            updatedPermissions[permission] = !updatedPermissions[permission];
            delete updatedPermissions['deny'];
          }

          return {
            ...screenPerm,
            permissions: updatedPermissions,
          };
        }
        return screenPerm;
      })
    );
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      const token = sessionStorage.getItem('authToken');
      const savePromises = screenPermissions.map((screenPerm) => {
        const selectedPermissions = Object.keys(screenPerm.permissions).filter(
          (key) => screenPerm.permissions[key as PermissionKey]
        );

        const permissionsToSend =
          selectedPermissions.length === 0 ? ['deny'] : selectedPermissions;

        return axios.put(
          `${import.meta.env.VITE_APP_API_URL}/screens/${
            screenPerm.screenId
          }/permissions`,
          {
            user_id: userId,
            permissions: permissionsToSend,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      });
      await Promise.all(savePromises);
      toast.success('Permissões salvas com sucesso!');
      navigate('/admin');
    } catch (error) {
      console.error('Erro ao salvar permissões:', error);
      if (axios.isAxiosError(error) && error.response) {
        const errorMessage =
          error.response.data.message ||
          'Erro desconhecido ao salvar as permissões.';
        toast.error(`Erro ao salvar as permissões: ${errorMessage}`);
      } else {
        toast.error(
          'Erro ao salvar as permissões. Por favor, tente novamente.'
        );
      }
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <PageContainer>
        <Header>
          <Title>Carregando...</Title>
        </Header>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Header>
        <Title>Permissões do Usuário</Title>
        <Description>
          Gerencie as permissões de acesso para cada tela do sistema.
        </Description>
      </Header>

      <ScreenGrid>
        {screens.map((screen) => {
          const screenPerm = screenPermissions.find(
            (sp) => sp.screenId === screen.id
          );
          const isDenied = screenPerm?.permissions['deny'] || false;
          const isRestricted = RESTRICTED_SCREENS.includes(screen.name);

          return (
            <ScreenCard key={screen.id}>
              <ScreenTitle>{screen.name}</ScreenTitle>
              <PermissionList>
                {Object.keys(permissionLabels).map((perm) => {
                  if (isRestricted && perm !== 'access') {
                    return null;
                  }
                  return (
                    <PermissionItem key={perm}>
                      <PermissionLabel>
                        <PermissionCheckbox
                          checked={
                            isDenied
                              ? false
                              : screenPerm?.permissions[
                                  perm as PermissionKey
                                ] || false
                          }
                          onChange={() =>
                            handlePermissionChange(
                              screen.id,
                              perm as PermissionKey
                            )
                          }
                          disabled={isDenied && perm !== 'access'}
                        />
                        <FontAwesomeIcon
                          icon={
                            permissionIcons[
                              perm as Exclude<PermissionKey, 'deny'>
                            ]
                          }
                          color={
                            isDenied
                              ? '#dc3545'
                              : screenPerm?.permissions[perm as PermissionKey]
                              ? '#28a745'
                              : '#6c757d'
                          }
                        />
                        <PermissionText>
                          {
                            permissionLabels[
                              perm as Exclude<PermissionKey, 'deny'>
                            ]
                          }
                        </PermissionText>
                      </PermissionLabel>
                    </PermissionItem>
                  );
                })}
              </PermissionList>
            </ScreenCard>
          );
        })}
      </ScreenGrid>

      <Footer>
        <SaveButton onClick={handleSave} disabled={saving}>
          <FontAwesomeIcon icon={faSave} />{' '}
          {saving ? 'Salvando...' : 'Salvar Permissões'}
        </SaveButton>
      </Footer>
    </PageContainer>
  );
};

export default PermissionPage;
