import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { Button, Form, Table, ProgressBar } from 'react-bootstrap';
import { refreshTokenWithLoading } from '../../components/Token/refreshToken';
import { formatCurrency } from '../../utils/formatCurrency';
import { FaUpload, FaTimes } from 'react-icons/fa'; // Ícones para os botões
import './vendas.css';
import { PermissionWrapper } from '../../components/Permission';

interface MeliAccount {
  id: string;
  nome: string;
  ativo: boolean;
}

type Payment = {
  total_paid_amount: number;
  operation_type: string;
  transaction_amount: number;
};

type OrderItem = {
  item: {
    listing_type_id: string;
    id: string;
    title: string;
    category_id?: string;
    variation_id?: string;
    seller_custom_field?: string;
    global_price?: number;
    net_weight?: number;
    variation_attributes?: any[];
    warranty?: string;
    condition?: string;
    seller_sku?: string;
  };
  quantity: number;
  unit_price: number;
  full_unit_price: number;
  currency_id: string;
  sale_fee: number;
  listing_type_id: string;
  logistic_type: string; // Novo campo para logistica
};

interface Sale {
  id: string;
  date_created: string;
  buyer: {
    nickname: string;
  };
  order_items: OrderItem[];
  total_amount: number;
  shipping: {
    id: number;
    cost: number;
  };
  tags: string[];
  status: string;
  payments: Payment[];
  receiver_address: {
    country: {
      id: string;
      name: string;
    };
    city: {
      id: string;
      name: string;
    };
    street_name: string;
    zip_code: string;
    receiver_name: string;
    state: {
      id: string;
      name: string;
    };
    address_line: string;
    neighborhood?: {
      id?: string;
      name?: string;
    };
    receiver_phone: string;
  };
}

const Vendas: React.FC = () => {
  const [sales, setSales] = useState<Sale[]>([]);
  const [filteredSales, setFilteredSales] = useState<Sale[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [selectedSales, setSelectedSales] = useState<string[]>([]);
  const [importing, setImporting] = useState<boolean>(false);
  const [importProgress, setImportProgress] = useState<number>(0);
  const [failedImports, setFailedImports] = useState<Sale[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false); // Novo estado de carregamento local

  const authToken = sessionStorage.getItem('authToken');

  const { data: meliAccounts = [], isLoading: isAccountsLoading } = useQuery<
    MeliAccount[],
    Error
  >({
    queryKey: ['meliAccounts', authToken],
    queryFn: async () => {
      try {
        const response = await axios.get(
          `${import.meta.env.VITE_APP_API_URL}/meli-contas`,
          {
            headers: { Authorization: `Bearer ${authToken}` },
          }
        );
        return response.data.data;
      } catch (error) {
        console.error('Erro ao buscar contas do Mercado Livre:', error);
        toast.error('Erro ao buscar contas do Mercado Livre');
        throw error;
      }
    },
  });

  const formatDateToISO = (
    date: string,
    isEndDate: boolean = false
  ): string => {
    const d = new Date(date);
    if (isEndDate) {
      d.setUTCHours(23, 59, 59, 999);
    }
    const isoDate = d.toISOString();
    return isoDate;
  };

  const fetchShippingDetails = async (shippingId: number) => {
    const token = sessionStorage.getItem('ml_access_token');

    if (!token) {
      console.error('Token ausente');
      toast.error('Token ausente');
      return null;
    }

    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/meli/shipments/${shippingId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error('Erro ao buscar detalhes do frete:', error);
      toast.error('Erro ao buscar detalhes do frete.');
      return null;
    }
  };

  const fetchSales = async (): Promise<Sale[]> => {
    const token = sessionStorage.getItem('ml_access_token');
    const userId = sessionStorage.getItem('ml_user_id');

    if (!token || !userId) {
      throw new Error('Token ou User ID ausente');
    }

    let url = `${
      import.meta.env.VITE_APP_API_URL
    }/meli/orders?seller=${userId}`;

    if (startDate && endDate) {
      const formattedStartDate = formatDateToISO(startDate);
      const formattedEndDate = formatDateToISO(endDate, true);
      url += `&order.date_created.from=${formattedStartDate}&order.date_created.to=${formattedEndDate}`;
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const salesData = response.data.results;

    for (const sale of salesData) {
      if (sale.shipping && sale.shipping.id) {
        const shippingDetails = await fetchShippingDetails(sale.shipping.id);

        sale.receiver_address = shippingDetails
          ? shippingDetails.receiver_address
          : null;

        sale.shipping.cost = shippingDetails
          ? shippingDetails.shipping_option.list_cost
          : 0;
        sale.shipping.full = shippingDetails
          ? shippingDetails.logistic_type === 'fulfillment'
          : false;

        // Atualize logistic_type para cada item
        sale.order_items.forEach((item: any) => {
          item.logistic_type = shippingDetails
            ? shippingDetails.logistic_type
            : 'normal';
        });
      }
    }

    return salesData;
  };

  const { refetch: refetchSales } = useQuery<Sale[], Error>({
    queryKey: ['sales', startDate, endDate, selectedAccount],
    queryFn: async () => {
      try {
        const data = await fetchSales();
        setSales(data);
        setFilteredSales(data);
        toast.success('Busca feita com sucesso!');
        return data;
      } catch (error) {
        console.error('Erro ao buscar vendas:', error);
        toast.error('Erro ao buscar vendas.');
        throw error;
      } finally {
        setIsLoading(false); // Encerrar o carregamento
      }
    },
    enabled: false, // Desativa a execução automática
  });

  const handleIntegrationCheck = async () => {
    if (selectedAccount) {
      setIsLoading(true); // Iniciar o carregamento imediatamente
      try {
        await refreshTokenWithLoading(selectedAccount, null);
        await refetchSales();
      } catch (error) {
        console.error('Erro ao verificar integração:', error);
        toast.error('Erro ao verificar integração.');
        setIsLoading(false); // Encerrar o carregamento em caso de erro
      }
    } else {
      toast.error('Selecione uma conta do Mercado Livre.');
    }
  };

  const getTotalPaidAmount = (payments: Payment[]) => {
    return payments.reduce(
      (sum, payment) => sum + (payment.total_paid_amount || 0),
      0
    );
  };

  const calculateTotal = (
    fullUnitPrice: number,
    saleFee: number,
    shippingCost: number
  ) => {
    return (fullUnitPrice || 0) - (saleFee || 0) - (shippingCost || 0);
  };

  const handleSelectSale = (
    e: React.ChangeEvent<HTMLInputElement>,
    saleId: string
  ) => {
    if (e.target.checked) {
      setSelectedSales((prevSelectedSales) => [...prevSelectedSales, saleId]);
    } else {
      setSelectedSales((prevSelectedSales) =>
        prevSelectedSales.filter((id) => id !== saleId)
      );
    }
  };

  const formatDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const handleSelectAll = () => {
    if (selectedSales.length === sales.length) {
      setSelectedSales([]);
    } else {
      setSelectedSales(sales.map((sale) => sale.id));
    }
  };

  const handleClearSelection = () => {
    setSelectedSales([]);
  };

  const handleImportSales = async () => {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      console.error('Token ausente');
      toast.error('Token ausente');
      return;
    }

    setImporting(true);
    setImportProgress(0);
    setFailedImports([]);

    try {
      for (let i = 0; i < selectedSales.length; i++) {
        const saleId = selectedSales[i];
        const sale = sales.find((sale) => sale.id === saleId);
        if (sale) {
          try {
            const data = {
              id_venda: sale.id.toString(),
              date_created: formatDate(sale.date_created),
              buyer_nickname: sale.buyer.nickname,
              item_title: sale.order_items[0].item.title,
              seller_sku: sale.order_items[0].item.seller_sku,
              quantity: sale.order_items[0].quantity,
              unit_price: sale.order_items[0].unit_price,
              full_unit_price: sale.order_items[0].full_unit_price,
              total_paid_amount: getTotalPaidAmount(sale.payments),
              sale_fee: sale.order_items[0].sale_fee,
              shipping_cost: sale.shipping.cost,
              listing_type: sale.order_items[0].listing_type_id,
              delivery_status: sale.tags.includes('not_delivered')
                ? 'Não Entregue'
                : 'Entregue',
              total: calculateTotal(
                sale.order_items[0].full_unit_price,
                sale.order_items[0].sale_fee,
                sale.shipping.cost
              ),
              receiver_name: sale.receiver_address?.receiver_name,
              address_line: sale.receiver_address?.address_line,
              city: sale.receiver_address?.city.name,
              state: sale.receiver_address?.state.name,
              zip_code: sale.receiver_address?.zip_code,
              receiver_phone: sale.receiver_address?.receiver_phone,
              meli_conta_id: selectedAccount,
              status: translateStatus(sale.status),
            };

            await axios.post(
              `${import.meta.env.VITE_APP_API_URL}/sales/import`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } catch (error) {
            console.error(`Erro ao importar venda ID ${sale.id}:`, error);
            setFailedImports((prev) => [...prev, sale]);
          }

          setImportProgress(Math.round(((i + 1) / selectedSales.length) * 100));
        }
      }

      if (failedImports.length > 0) {
        toast.error(`Erro ao importar algumas vendas.`);
      } else {
        toast.success('Vendas importadas com sucesso!');
      }
    } catch (error) {
      console.error('Erro ao importar vendas:', error);
      toast.error('Erro ao importar vendas.');
    } finally {
      setImporting(false);
    }
  };

  const checkIfStockProcessed = async (saleId: any, meliContaId: any) => {
    try {
      const response = await axios.get(
        `${
          import.meta.env.VITE_APP_API_URL
        }/check-stock-processed?id_venda=${saleId}&meli_conta_id=${meliContaId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data.isok) {
        toast.warn(
          'O estoque já foi atualizado para este pedido. Verifique o relatório de movimento de estoque.'
        );
        return true;
      }

      return false;
    } catch (error) {
      console.error('Erro ao verificar se o estoque já foi processado:', error);
      return false;
    }
  };

  const handleImportSalesWithStock = async () => {
    const token = sessionStorage.getItem('authToken');
    if (!token) {
      console.error('Token ausente');
      toast.error('Token ausente');
      return;
    }

    setImporting(true);
    setImportProgress(0);
    setFailedImports([]); // Reinicializa a lista de vendas que falharam

    try {
      let allSuccessful = true; // Inicializa uma flag para rastrear o sucesso de todas as importações
      let alreadyProcessed = false; // Inicializa uma flag para rastrear se algum pedido já foi processado

      for (let i = 0; i < selectedSales.length; i++) {
        const saleId = selectedSales[i];
        const sale = sales.find((sale) => sale.id === saleId);

        if (sale) {
          try {
            const meliContaId =
              sale.order_items[0].logistic_type === 'fulfillment'
                ? selectedAccount
                : 0;

            // Verifica se o estoque já foi processado
            const saleAlreadyProcessed = await checkIfStockProcessed(
              sale.id,
              meliContaId
            );
            if (saleAlreadyProcessed) {
              alreadyProcessed = true; // Define a flag como true se algum pedido já foi processado
              continue; // Pula o processamento se o estoque já foi processado
            }

            // 1. Verificar o estoque
            const sku = sale.order_items[0].item.seller_sku;
            const stockResponse = await axios.get(
              `${
                import.meta.env.VITE_APP_API_URL
              }/check-stock?sku=${sku}&meli_conta_id=${meliContaId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (!stockResponse.data.isok) {
              toast.error(
                `Estoque não encontrado para SKU ${sku} e Conta ID ${meliContaId}`
              );
              setFailedImports((prev) => [...prev, sale]);
              allSuccessful = false; // Define como falso se alguma venda falhar
              continue;
            }

            const estoqueId = stockResponse.data.estoque_id;
            const produtoId = stockResponse.data.produto_id;
            const quantidadeEmEstoque = stockResponse.data.quantidade;
            const quantidadeVendida = sale.order_items[0].quantity;

            // 2. Verificar se há estoque suficiente
            if (quantidadeEmEstoque < quantidadeVendida) {
              toast.error(
                `Estoque insuficiente para SKU ${sku}: Quantidade em estoque (${quantidadeEmEstoque}) é menor que a vendida (${quantidadeVendida})`
              );
              setFailedImports((prev) => [...prev, sale]);
              allSuccessful = false; // Define como falso se alguma venda falhar
              continue;
            }

            // 3. Atualizar o estoque
            const novaQuantidade = quantidadeEmEstoque - quantidadeVendida;
            await axios.put(
              `${import.meta.env.VITE_APP_API_URL}/estoques/${estoqueId}`,
              {
                produto_id: produtoId,
                quantidade: novaQuantidade,
                meli_conta_id: meliContaId,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            // 4. Registrar movimentação de estoque
            await axios.post(
              `${import.meta.env.VITE_APP_API_URL}/movimento-estoques`,
              {
                estoque_id: estoqueId,
                produto_id: produtoId,
                quantidade: quantidadeVendida,
                tipo: 'Importação Vendas Automático',
                usuario: sessionStorage.getItem('userEmail'),
                id_venda: sale.id,
                meli_conta_id: meliContaId,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            // Atualiza o progresso
            setImportProgress(
              Math.round(((i + 1) / selectedSales.length) * 100)
            );
          } catch (error) {
            console.error(`Erro ao processar venda ID ${sale.id}:`, error);
            setFailedImports((prev) => [...prev, sale]);
            allSuccessful = false; // Define como falso se alguma venda falhar
          }
        }
      }

      // Verifica se todas as vendas foram importadas com sucesso e se não houve nenhum pedido já processado
      if (allSuccessful && !alreadyProcessed) {
        toast.success('Vendas e estoque importados com sucesso!');
      } else if (!allSuccessful) {
        toast.error('Erro ao importar algumas vendas.'); // Exibe uma mensagem de erro se houve alguma falha
      }
    } catch (error) {
      console.error('Erro ao importar vendas:', error);
      toast.error('Erro ao importar vendas.');
    } finally {
      setImporting(false);
    }
  };

  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();

    const filteredData = sales.filter((sale) => {
      return (
        sale.id.toString().toLowerCase().includes(lowercasedQuery) ||
        sale.buyer.nickname?.toLowerCase().includes(lowercasedQuery) ||
        sale.order_items.some(
          (item) =>
            item.item.title?.toLowerCase().includes(lowercasedQuery) ||
            item.item.seller_sku?.toLowerCase().includes(lowercasedQuery)
        ) ||
        sale.status?.toLowerCase().includes(lowercasedQuery) || // Verifica o status original
        translateStatus(sale.status).toLowerCase().includes(lowercasedQuery) || // Verifica o status traduzido
        sale.tags?.some((tag) =>
          tag?.toLowerCase().includes(lowercasedQuery)
        ) || // Verifica tags
        (sale.receiver_address &&
          (sale.receiver_address.receiver_name
            ?.toLowerCase()
            .includes(lowercasedQuery) ||
            sale.receiver_address.address_line
              ?.toLowerCase()
              .includes(lowercasedQuery) ||
            sale.receiver_address.city?.name
              ?.toLowerCase()
              .includes(lowercasedQuery) ||
            sale.receiver_address.state?.name
              ?.toLowerCase()
              .includes(lowercasedQuery) ||
            sale.receiver_address.zip_code
              ?.toLowerCase()
              .includes(lowercasedQuery) ||
            sale.receiver_address.receiver_phone
              ?.toLowerCase()
              .includes(lowercasedQuery)))
      );
    });

    setFilteredSales(filteredData);
  }, [searchQuery, sales]);

  const translateStatus = (status: string) => {
    switch (status) {
      case 'paid':
        return 'Pago';
      case 'delivered':
        return 'Entregue';
      case 'not_delivered':
        return 'Não Entregue';
      case 'cancelled':
        return 'Cancelado';
      default:
        return 'Desconhecido';
    }
  };

  return (
    <PermissionWrapper screenId={'6'} permission="access">
      <div className="container-fluid">
        <h1 className="text-center mb-4">Vendas do Mercado Livre</h1>
        <div className="row">
          <div className="col-md-4 mb-3">
            <Form.Group>
              <Form.Label>Conta do Mercado Livre</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setSelectedAccount(e.target.value)}
              >
                <option value="">Selecione uma conta</option>
                {meliAccounts?.map((account: MeliAccount) => (
                  <option key={account.id} value={account.id}>
                    {account.nome} {account.ativo ? '(Ativo)' : ''}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="col-md-4 mb-3">
            <Form.Group>
              <Form.Label>Data Inicial</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-md-4 mb-3">
            <Form.Group>
              <Form.Label>Data Final</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>

        <Button
          onClick={handleIntegrationCheck}
          className="mt-3 w-100"
          variant="primary"
          disabled={isLoading || isAccountsLoading} // Desabilita o botão durante o carregamento
        >
          {isLoading || isAccountsLoading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <>
              <img
                src="./mercado_livre.png"
                alt="Mercado Livre"
                style={{ width: '40px', marginRight: '10px' }}
              />
              Buscar Vendas no Mercado Livre
            </>
          )}
        </Button>

        <div className="mt-4">
          {sales.length > 0 ? (
            <>
              {/* Campo de Pesquisa */}
              <div className="row mt-4">
                <div className="col-md-12">
                  <Form.Control
                    type="text"
                    placeholder="Pesquise por qualquer coisa..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </div>

              {/* Tabela de Vendas */}
              <div className="table-responsive mt-4">
                <Table striped hover responsive>
                  <thead className="thead-dark">
                    <tr>
                      {/* Definição das colunas da tabela */}
                      <th>
                        <input
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={
                            selectedSales.length === filteredSales.length
                          }
                        />
                      </th>
                      <th>ID da Venda</th>
                      <th>Estoque</th>
                      <th>Data</th>
                      <th>Comprador</th>
                      <th>Título do Item</th>
                      <th>SKU</th>
                      <th>Quantidade</th>
                      <th>Preço Unitário</th>
                      <th>Preço Total do Item</th>
                      <th>Valor Total Pago</th>
                      <th>Tarifa de Venda</th>
                      <th>Tarifa de Envio</th>
                      <th>Tipo de Anúncio</th>
                      <th>Status de Entrega</th>
                      <th>Total</th>
                      <th>Status</th>
                      <th>Nome do Recebedor</th>
                      <th>Endereço</th>
                      <th>Cidade</th>
                      <th>Estado</th>
                      <th>CEP</th>
                      <th>Telefone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredSales.length > 0 ? (
                      filteredSales.map((sale) =>
                        sale.order_items.map((item, index) => (
                          <tr key={`${sale.id}-${index}`}>
                            <td>
                              <input
                                type="checkbox"
                                onChange={(e) => handleSelectSale(e, sale.id)}
                                checked={selectedSales.includes(sale.id)}
                              />
                            </td>
                            <td>{sale.id || ''}</td>
                            <td>
                              {item.logistic_type === 'fulfillment'
                                ? 'Full'
                                : 'Normal'}
                            </td>
                            <td>
                              {sale.date_created
                                ? new Date(sale.date_created).toLocaleString()
                                : ''}
                            </td>
                            <td>{sale.buyer?.nickname || ''}</td>
                            <td>{item.item?.title || ''}</td>
                            <td>{item.item?.seller_sku || ''}</td>
                            <td>{item.quantity || ''}</td>
                            <td>{formatCurrency(item.unit_price) || ''}</td>
                            <td>
                              {formatCurrency(item.full_unit_price) || ''}
                            </td>
                            <td>
                              {formatCurrency(
                                getTotalPaidAmount(sale.payments)
                              ) || ''}
                            </td>
                            <td>{formatCurrency(item.sale_fee) || ''}</td>
                            <td>{formatCurrency(sale.shipping?.cost) || ''}</td>
                            <td>
                              {item.listing_type_id === 'gold_special'
                                ? 'Anúncio em Destaque'
                                : item.listing_type_id === 'gold'
                                ? 'Anúncio Pago com Destaque'
                                : item.listing_type_id === 'silver'
                                ? 'Anúncio Básico'
                                : 'Desconhecido'}
                            </td>
                            <td>
                              {sale.tags?.includes('not_delivered')
                                ? 'Não Entregue'
                                : 'Entregue'}
                            </td>
                            <td>
                              {formatCurrency(
                                calculateTotal(
                                  item.full_unit_price,
                                  item.sale_fee,
                                  sale.shipping?.cost
                                )
                              ) || ''}
                            </td>
                            <td>{translateStatus(sale.status) || ''}</td>
                            <td>
                              {sale.receiver_address?.receiver_name || ''}
                            </td>
                            <td>{sale.receiver_address?.address_line || ''}</td>
                            <td>{sale.receiver_address?.city?.name || ''}</td>
                            <td>{sale.receiver_address?.state?.name || ''}</td>
                            <td>{sale.receiver_address?.zip_code || ''}</td>
                            <td>
                              {sale.receiver_address?.receiver_phone || ''}
                            </td>
                          </tr>
                        ))
                      )
                    ) : (
                      <tr>
                        <td colSpan={21} className="text-center">
                          Nenhum resultado encontrado.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            <p className="text-center">Nenhuma venda encontrada.</p>
          )}
        </div>

        <div className="sticky-footer">
          <Button
            onClick={handleImportSales}
            className="mr-2 d-flex align-items-center justify-content-center"
            variant="success"
            disabled={selectedSales.length === 0 || importing}
          >
            {importing ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="ml-2">Importando ({importProgress}%)</span>
              </>
            ) : (
              <>
                <FaUpload className="mr-2" /> {/* Ícone de upload */}
                Importar Vendas
              </>
            )}
          </Button>

          {/* Novo botão para importar vendas com atualização de estoque */}
          <Button
            onClick={handleImportSalesWithStock}
            className="mr-2 d-flex align-items-center justify-content-center"
            variant="warning"
            disabled={selectedSales.length === 0 || importing}
          >
            {importing ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="ml-2">
                  Atualizar Estoque Normal/Full ({importProgress}%)
                </span>
              </>
            ) : (
              <>
                <FaUpload className="mr-2" /> {/* Ícone de upload */}
                Atualizar Estoque Normal/Full
              </>
            )}
          </Button>

          <Button
            onClick={handleClearSelection}
            className="ml-2 d-flex align-items-center justify-content-center"
            variant="secondary"
            disabled={selectedSales.length === 0 || importing}
          >
            <FaTimes className="mr-2" /> {/* Ícone de cancelamento/limpeza */}
            Limpar Seleção
          </Button>
        </div>

        {importing && (
          <ProgressBar
            now={importProgress}
            label={`${importProgress}%`}
            className="mt-2"
          />
        )}
        {failedImports.length > 0 && (
          <div className="mt-4 alert alert-danger">
            <h5>As seguintes vendas falharam na importação:</h5>
            <ul>
              {failedImports.map((sale) => (
                <li key={sale.id}>
                  {sale.id} - {sale.buyer.nickname}
                </li>
              ))}
            </ul>
          </div>
        )}

        <hr />
      </div>
    </PermissionWrapper>
  );
};

export default Vendas;
