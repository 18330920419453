import React, { useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import {
  Modal,
  Button,
  Form,
  Table,
  Spinner,
  InputGroup,
  FormControl,
  FormSelect,
} from 'react-bootstrap';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {
  Product,
  Estoque,
  EstoqueLocal,
  ApiResponseData,
} from '../../components/types';
import {
  FaFileExcel,
  FaFilePdf,
  FaPlus,
  FaMinus,
  FaEdit,
  FaSearch,
} from 'react-icons/fa';
import Select from 'react-select';
import { PermissionWrapper } from '../../components/Permission';

type OptionType = {
  value: string;
  label: string;
};

// Função para buscar estoques com tipagem correta
const fetchEstoques = async (
  page: number,
  perPage: number,
  search: string
): Promise<ApiResponseData<EstoqueLocal[]>> => {
  const token = sessionStorage.getItem('authToken');
  if (!token) {
    throw new Error('Token não encontrado. Por favor, faça login novamente.');
  }

  let url = `${
    import.meta.env.VITE_APP_API_URL
  }/estoquelocal?page=${page}&per_page=${perPage}`;
  if (search) {
    url += `&search=${search}`;
  }

  const response = await axios.get<ApiResponseData<EstoqueLocal[]>>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Função para buscar produtos com tipagem correta
const fetchProdutos = async (): Promise<Product[]> => {
  const token = sessionStorage.getItem('authToken');
  if (!token) {
    throw new Error('Token não encontrado. Por favor, faça login novamente.');
  }

  const response = await axios.get<ApiResponseData<Product[]>>(
    `${import.meta.env.VITE_APP_API_URL}/selectProdutos`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data.data;
};

const EstoquePage: React.FC = () => {
  const [showEntradaModal, setShowEntradaModal] = useState(false);
  const [showSaidaModal, setShowSaidaModal] = useState(false);
  const [showAjusteModal, setShowAjusteModal] = useState(false);
  const [selectedProduto, setSelectedProduto] = useState<string | number>('');
  const [entradaQuantidade, setEntradaQuantidade] = useState<number>(0);
  const [saidaQuantidade, setSaidaQuantidade] = useState<number>(0);
  const [ajusteQuantidade, setAjusteQuantidade] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(5);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [reloadCharts, setReloadCharts] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [tipoAjuste, setTipoAjuste] = useState('local');
  const [contaMeliId, setContaMeliId] = useState<string | number>('');

  const token = sessionStorage.getItem('authToken');
  const user = sessionStorage.getItem('userEmail');

  // Usando o useQuery para buscar os estoques com tipagem correta
  const {
    data: estoquesData,
    isLoading,
    refetch,
  } = useQuery<ApiResponseData<EstoqueLocal[]>, Error>({
    queryKey: ['estoques', currentPage, perPage, search],
    queryFn: () => fetchEstoques(currentPage, perPage, search),
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  // Usando o useQuery para buscar os produtos
  const { data: produtos } = useQuery<Product[], Error>({
    queryKey: ['produtosEstoque'],
    queryFn: fetchProdutos,
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  // Extraia os dados do estoque a partir de estoquesData
  const estoques = estoquesData?.data ?? [];
  const totalPages = estoquesData?.meta?.last_page ?? 1;
  const total = estoquesData?.meta?.total ?? 0;
  const to = estoquesData?.meta?.to ?? 0;

  const [meliAccounts, setMeliAccounts] = useState<OptionType[]>([]);

  // Função para buscar as contas do Mercado Livre
  const fetchMeliAccounts = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_APP_API_URL}/meli-contas`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Transformar a resposta em opções para o Select
      const options = response.data.data.map((account: any) => ({
        value: account.id.toString(),
        label: account.nome, // Ajuste conforme necessário para o nome da conta
      }));

      setMeliAccounts(options);
    } catch (error) {
      console.error('Erro ao buscar contas do Mercado Livre:', error);
      toast.error('Erro ao buscar contas do Mercado Livre');
    }
  };

  // Use useEffect para buscar as contas ao carregar o componente
  useEffect(() => {
    fetchMeliAccounts();
  }, []);

  useEffect(() => {
    if (reloadCharts) {
      setReloadCharts(false);
    }
  }, [reloadCharts]);

  const handleShowEntradaModal = () => setShowEntradaModal(true);
  const handleCloseEntradaModal = () => {
    setShowEntradaModal(false);
    setSelectedProduto('');
    setEntradaQuantidade(0);
  };

  const handleShowSaidaModal = () => setShowSaidaModal(true);
  const handleCloseSaidaModal = () => {
    setShowSaidaModal(false);
    setSelectedProduto('');
    setSaidaQuantidade(0);
  };

  const handleShowAjusteModal = () => setShowAjusteModal(true);
  const handleCloseAjusteModal = () => {
    setShowAjusteModal(false);
    setSelectedProduto('');
    setAjusteQuantidade(0);
  };

  const handleSalvarEntrada = async () => {
    if (!token) {
      toast.error('Token não encontrado. Por favor, faça login novamente.');
      return;
    }

    if (selectedProduto === '') {
      toast.error('Você deve selecionar um produto.');
      return;
    }

    if (entradaQuantidade <= 0) {
      toast.error('A quantidade deve ser maior que 0.');
      return;
    }

    setLoading(true);
    try {
      const produtoId = parseInt(selectedProduto.toString());
      const estoqueExistente = estoques.find(
        (e) => e.produto_id === produtoId && e.meli_conta_id === 0
      );

      if (estoqueExistente) {
        const novaQuantidade = estoqueExistente.quantidade + entradaQuantidade;
        await axios.put(
          `${import.meta.env.VITE_APP_API_URL}/estoques/${estoqueExistente.id}`,
          {
            produto_id: produtoId,
            quantidade: novaQuantidade,
            meli_conta_id: 0,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/movimento-estoques`,
          {
            estoque_id: estoqueExistente.id,
            produto_id: produtoId,
            quantidade: entradaQuantidade,
            tipo: 'Entrada',
            usuario: user,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        const newEstoqueResponse: AxiosResponse<ApiResponseData<Estoque>> =
          await axios.post(
            `${import.meta.env.VITE_APP_API_URL}/estoques`,
            {
              produto_id: produtoId,
              quantidade: entradaQuantidade,
              meli_conta_id: 0,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

        const newEstoqueId = newEstoqueResponse.data.data.id;

        await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/movimento-estoques`,
          {
            estoque_id: newEstoqueId,
            produto_id: produtoId,
            quantidade: entradaQuantidade,
            tipo: 'Entrada',
            usuario: user,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      toast.success('Entrada de estoque realizada com sucesso!');
      refetch(); // Refaz a busca após criar ou editar um produto
      setReloadCharts(true); // Sinaliza que os gráficos precisam ser recarregados
      handleCloseEntradaModal();
    } catch (error) {
      toast.error('Erro ao salvar entrada de estoque.');
    } finally {
      setLoading(false);
    }
  };

  const handleSalvarSaida = async () => {
    if (!token) {
      toast.error('Token não encontrado. Por favor, faça login novamente.');
      return;
    }

    if (selectedProduto === '') {
      toast.error('Você deve selecionar um produto.');
      return;
    }

    if (saidaQuantidade <= 0) {
      toast.error('A quantidade deve ser maior que 0.');
      return;
    }

    setLoading(true);
    try {
      const produtoId = parseInt(selectedProduto.toString());
      const estoqueExistente = estoques.find(
        (e) => e.produto_id === produtoId && e.meli_conta_id === 0
      );

      if (estoqueExistente) {
        const novaQuantidade = estoqueExistente.quantidade - saidaQuantidade;
        if (novaQuantidade < 0) {
          toast.error(
            'Quantidade insuficiente no estoque. Estoque Atual Qtde:' +
              estoqueExistente.quantidade
          );
          setLoading(false);
          return;
        }

        await axios.put(
          `${import.meta.env.VITE_APP_API_URL}/estoques/${estoqueExistente.id}`,
          {
            produto_id: produtoId,
            quantidade: novaQuantidade,
            meli_conta_id: 0,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/movimento-estoques`,
          {
            estoque_id: estoqueExistente.id,
            produto_id: produtoId,
            quantidade: saidaQuantidade,
            tipo: 'Saída',
            usuario: user,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        toast.error('Estoque não encontrado.');
      }

      toast.success('Saída de estoque realizada com sucesso!');
      refetch(); // Refaz a busca após criar ou editar um produto
      setReloadCharts(true); // Sinaliza que os gráficos precisam ser recarregados
      handleCloseSaidaModal();
    } catch (error) {
      toast.error('Erro ao salvar saída de estoque.');
    } finally {
      setLoading(false);
    }
  };

  const handleSalvarAjuste = async () => {
    if (!token) {
      toast.error('Token não encontrado. Por favor, faça login novamente.');
      return;
    }

    if (selectedProduto === '') {
      toast.error('Você deve selecionar um produto.');
      return;
    }

    setLoading(true);
    try {
      const produtoId = parseInt(selectedProduto.toString());
      const meliContaId = tipoAjuste === 'meli' ? contaMeliId.toString() : '0'; // Convertendo para string

      // Tenta encontrar o estoque existente
      let estoqueExistente = estoques.find(
        (e) =>
          e.produto_id === produtoId &&
          e.meli_conta_id.toString() === meliContaId
      );

      if (estoqueExistente) {
        const novaQuantidade = ajusteQuantidade;
        await axios.put(
          `${import.meta.env.VITE_APP_API_URL}/estoques/${estoqueExistente.id}`,
          {
            produto_id: produtoId,
            quantidade: novaQuantidade,
            meli_conta_id: meliContaId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        // Cria um novo registro de estoque caso não seja encontrado
        const newEstoqueResponse = await axios.post(
          `${import.meta.env.VITE_APP_API_URL}/estoques`,
          {
            produto_id: produtoId,
            quantidade: ajusteQuantidade,
            meli_conta_id: meliContaId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        estoqueExistente = newEstoqueResponse.data.data;
      }

      // Verifica se o estoqueExistente foi corretamente definido
      if (!estoqueExistente) {
        throw new Error('Erro ao criar ou acessar o estoque');
      }

      // Grava o movimento de estoque
      await axios.post(
        `${import.meta.env.VITE_APP_API_URL}/movimento-estoques`,
        {
          estoque_id: estoqueExistente.id,
          produto_id: produtoId,
          quantidade: ajusteQuantidade,
          tipo: 'Ajuste',
          usuario: user,
          meli_conta_id: meliContaId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success('Ajuste de estoque realizado com sucesso!');
      refetch(); // Refaz a busca após criar ou editar um produto
      setReloadCharts(true); // Sinaliza que os gráficos precisam ser recarregados
      handleCloseAjusteModal();
    } catch (error) {
      toast.error('Erro ao salvar ajuste de estoque.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const exportToExcel = async () => {
    setExportLoading(true);
    try {
      const ws = XLSX.utils.json_to_sheet(estoques);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Estoques');
      XLSX.writeFile(wb, 'estoques.xlsx');
      toast.success('Exportação para Excel realizada com sucesso!');
    } catch (error) {
      toast.error('Erro ao exportar para Excel.');
    } finally {
      setExportLoading(false);
    }
  };

  const exportToPDF = async () => {
    setExportLoading(true);
    try {
      const pdf = new jsPDF();
      const title = 'Relatório de Estoque';
      const tableColumn = [
        'SKU',
        'Produto',
        'Quantidade Local',
        ...contasMeli.map((conta) => `Qtde Meli Conta ${conta}`),
      ];
      const tableRows: any[] = [];

      estoquesAgrupados.forEach((estoque) => {
        const rowData = [
          estoque.sku,
          estoque.descricao,
          estoque.quantidadeLocal,
          ...contasMeli.map((conta) => estoque.contasMeli[conta] || ''),
        ];
        tableRows.push(rowData);
      });

      pdf.setFontSize(18);
      pdf.text(title, 13, 8);
      pdf.setFontSize(12);
      pdf.text('Data: ' + new Date().toLocaleDateString(), 165, 8);

      autoTable(pdf, {
        head: [tableColumn],
        body: tableRows,
        startY: 10,
        theme: 'grid',
        styles: { fontSize: 8, cellPadding: 2 },
        headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255] },
      });

      pdf.save('estoques.pdf');
      toast.success('Exportação para PDF realizada com sucesso!');
    } catch (error) {
      console.error('Erro ao exportar para PDF:', error);
      toast.error('Erro ao exportar para PDF.');
    } finally {
      setExportLoading(false);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);
    let startPage = Math.max(1, currentPage - halfMaxPagesToShow);
    let endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow);

    if (currentPage <= halfMaxPagesToShow) {
      endPage = Math.min(totalPages, maxPagesToShow);
    } else if (currentPage + halfMaxPagesToShow >= totalPages) {
      startPage = Math.max(1, totalPages - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const agruparEstoquesPorSKUeProdutoId = (estoques: EstoqueLocal[]) => {
    const agrupado: { [key: string]: any } = {};
    const todasAsContasMeli = new Set<string>();

    estoques.forEach((estoque) => {
      const { sku, descricao, quantidade, nome, produto_id } = estoque;
      const chaveAgrupamento = `${sku}-${produto_id}`;

      if (!agrupado[chaveAgrupamento]) {
        agrupado[chaveAgrupamento] = {
          sku,
          produto_id,
          descricao,
          quantidadeLocal: 0,
          contasMeli: {},
        };
      }
      if (nome) {
        agrupado[chaveAgrupamento].contasMeli[nome] = quantidade;
        todasAsContasMeli.add(nome);
      } else {
        agrupado[chaveAgrupamento].quantidadeLocal = quantidade;
      }
    });

    return {
      agrupado: Object.values(agrupado),
      contasMeli: Array.from(todasAsContasMeli),
    };
  };

  const { agrupado: estoquesAgrupados, contasMeli } =
    agruparEstoquesPorSKUeProdutoId(estoques);

  const produtoOptions =
    produtos?.map((produto) => ({
      value: produto.id,
      label: produto.descricao + ' : ' + produto.sku,
    })) || [];

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      refetch(); // Refaz a busca com a nova pesquisa
    }
  };

  return (
    <PermissionWrapper screenId={'2'} permission="access">
      <div className="container-fluid">
        <InputGroup className="mb-3 no-border-radius">
          <FormControl
            placeholder="Pesquisar"
            aria-label="Pesquisar"
            value={search}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyPress}
          />
          <InputGroup.Text>
            <Button
              variant="outline-secondary"
              onClick={() => fetchEstoques(1, 0, search)}
            >
              <FaSearch />
            </Button>
          </InputGroup.Text>
        </InputGroup>
        <div className="d-flex flex-wrap">
          <Button
            variant="primary"
            onClick={handleShowEntradaModal}
            className="mr-2 mb-2 ml-2 no-border-radius"
          >
            <FaPlus /> Entrada de Estoque Local
          </Button>
          <Button
            variant="danger"
            onClick={handleShowSaidaModal}
            className="mr-2 mb-2 mx-2 no-border-radius"
          >
            <FaMinus /> Saída de Estoque Local
          </Button>
          <Button
            variant="warning"
            onClick={handleShowAjusteModal}
            className="mr-2 mb-2 mx-2 no-border-radius"
          >
            <FaEdit /> Ajuste de Estoque Local e Meli
          </Button>
          <Button
            variant="info"
            onClick={exportToExcel}
            className="mr-2 mb-2 mx-2"
            disabled={exportLoading}
          >
            {exportLoading ? (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>
                <FaFileExcel /> Exportar para Excel
              </>
            )}
          </Button>
          <Button
            variant="warning"
            onClick={exportToPDF}
            className="mr-2 mb-2"
            disabled={exportLoading}
          >
            {exportLoading ? (
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>
                <FaFilePdf /> Exportar para PDF
              </>
            )}
          </Button>
          <FormSelect
            value={perPage}
            onChange={handlePerPageChange}
            className="mb-2 mx-2"
            style={{ width: '100px' }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={150}>150</option>
            <option value={500}>500</option>
            <option value={1000}>1000</option>
            <option value={5000}>5000</option>
          </FormSelect>
        </div>

        {isLoading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">@</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <Table
                striped
                bordered
                className="table-striped"
                id="table-to-print"
              >
                <thead className="thead-dark table table-dark">
                  <tr>
                    <th>Sku</th>
                    <th>Produto</th>
                    <th>Quantidade Local</th>
                    {contasMeli.map((conta, index) => (
                      <th key={index}>Qtde Meli Conta {conta}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {estoquesAgrupados.map((estoque, index) => (
                    <tr
                      key={`estoque-${estoque.sku}-${estoque.produto_id}-${index}`}
                    >
                      <td>{estoque.sku}</td>
                      <td>{estoque.descricao}</td>
                      <td>{estoque.quantidadeLocal}</td>
                      {contasMeli.map((conta, i) => (
                        <td key={`estoque-conta-${estoque.sku}-${conta}-${i}`}>
                          {estoque.contasMeli[conta] || ''}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <br />
            <p className="text-muted">
              Mostrando {to} de {total} estoques
            </p>
            <nav>
              <ul className="pagination justify-content-center">
                <li
                  className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Anterior
                  </button>
                </li>
                {getPageNumbers().map((pageNumber) => (
                  <li
                    key={pageNumber}
                    className={`page-item ${
                      currentPage === pageNumber ? 'active' : ''
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? 'disabled' : ''
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Próxima
                  </button>
                </li>
              </ul>
            </nav>
          </>
        )}

        <Modal show={showEntradaModal} onHide={handleCloseEntradaModal}>
          <Modal.Header closeButton>
            <Modal.Title>Entrada de Estoque</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Produto</Form.Label>
                <Select
                  key={showEntradaModal ? 'open' : 'closed'}
                  options={produtoOptions}
                  onChange={(selectedOption) =>
                    setSelectedProduto(
                      selectedOption ? selectedOption.value.toString() : ''
                    )
                  }
                  isClearable
                  placeholder="Buscar e selecionar produto..."
                />
                <Form.Control.Feedback type="invalid">
                  Você deve selecionar um produto.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Quantidade</Form.Label>
                <Form.Control
                  type="number"
                  value={entradaQuantidade}
                  onChange={(e) =>
                    setEntradaQuantidade(parseInt(e.target.value))
                  }
                  isInvalid={entradaQuantidade <= 0}
                />
                <Form.Control.Feedback type="invalid">
                  A quantidade deve ser maior que 0.
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEntradaModal}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={handleSalvarEntrada}
              disabled={loading}
            >
              {loading ? 'Salvando...' : 'Salvar'}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showSaidaModal} onHide={handleCloseSaidaModal}>
          <Modal.Header closeButton>
            <Modal.Title>Saída de Estoque</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Produto</Form.Label>
                <Select
                  key={showSaidaModal ? 'saida' : ''}
                  options={produtoOptions}
                  onChange={(selectedOption) =>
                    setSelectedProduto(
                      selectedOption ? selectedOption.value.toString() : ''
                    )
                  }
                  isClearable
                  placeholder="Buscar e selecionar produto..."
                />
                <Form.Control.Feedback type="invalid">
                  Você deve selecionar um produto.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Quantidade</Form.Label>
                <Form.Control
                  type="number"
                  value={saidaQuantidade}
                  onChange={(e) => setSaidaQuantidade(parseInt(e.target.value))}
                  isInvalid={saidaQuantidade <= 0}
                />
                <Form.Control.Feedback type="invalid">
                  A quantidade deve ser maior que 0.
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseSaidaModal}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={handleSalvarSaida}
              disabled={loading}
            >
              {loading ? 'Salvando...' : 'Salvar'}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showAjusteModal} onHide={handleCloseAjusteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Ajuste de Estoque</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Produto</Form.Label>
                <Select
                  key={showAjusteModal ? 'ajuste' : ''}
                  options={produtoOptions}
                  onChange={(selectedOption) =>
                    setSelectedProduto(
                      selectedOption ? selectedOption.value.toString() : ''
                    )
                  }
                  isClearable
                  placeholder="Buscar e selecionar produto..."
                />
                <Form.Control.Feedback type="invalid">
                  Você deve selecionar um produto.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Tipo de Ajuste</Form.Label>
                <FormSelect
                  onChange={(e) => setTipoAjuste(e.target.value)}
                  value={tipoAjuste}
                >
                  <option value="local">Local</option>
                  <option value="meli">Mercado Livre</option>
                </FormSelect>
              </Form.Group>

              {tipoAjuste === 'meli' && (
                <Form.Group>
                  <Form.Label>Conta Mercado Livre</Form.Label>
                  <Select
                    options={meliAccounts}
                    onChange={(selectedOption) =>
                      setContaMeliId(selectedOption ? selectedOption.value : '')
                    }
                    isClearable
                    placeholder="Selecione a conta do Mercado Livre..."
                  />
                </Form.Group>
              )}

              <Form.Group>
                <Form.Label>Quantidade</Form.Label>
                <Form.Control
                  type="number"
                  value={ajusteQuantidade}
                  onChange={(e) =>
                    setAjusteQuantidade(parseInt(e.target.value))
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAjusteModal}>
              Cancelar
            </Button>
            <Button
              variant="primary"
              onClick={handleSalvarAjuste}
              disabled={loading}
            >
              {loading ? 'Salvando...' : 'Salvar'}
            </Button>
          </Modal.Footer>
        </Modal>

        <hr />
      </div>
    </PermissionWrapper>
  );
};

export default EstoquePage;
